
<template>
  <el-form :inline="true" label-position="top"
    :model="localForm"
    :status-icon="true"
    ref="localForm">
    <el-form-item
      v-if="!primaryGuest"
      class="guest-from--use-primary-guest">
      <el-checkbox
        v-model="usePrimaryGuest" @change="setPrimaryGuestToLocalForm"> Same as primary guest</el-checkbox>
    </el-form-item>
    <el-form-item
      class="guest-from--guest-first-name"
      id="kdoi"
      label="Guest given name"
      prop="firstName"
      :show-message="!isUsePrimaryGuestAsLocalForm"
      :rules="[
        { type: 'string', message: 'Please type your first name in A-Z, 0-9', trigger: [ 'change', 'blur' ], pattern: /^[\w\d\s_\-]+$/ig },
        { required: true, message: 'First name is required', trigger: [ 'change', 'blur' ] }
      ]">
      <el-input placeholder="E.g: Alice"
        name="roomLeadGuestFirstName"
        autocomplete="given-name"
        v-model.trim="localForm.firstName"
        @blur="handleBlur"
        :disabled="isUsePrimaryGuestAsLocalForm"/>
      <p class="form-help-text">Please tell us who is going to stay.</p>
    </el-form-item>
    <el-form-item
      class="guest-from--guest-last-name"
      label="Guest family name"
      prop="lastName"
      :show-message="!isUsePrimaryGuestAsLocalForm"
      :rules="[
        { type: 'string', message: 'Please type your last name in A-Z, 0-9', trigger: [ 'change', 'blur' ], pattern: /^[\w\d\s_\-]+$/ig },
        { required: true, message: 'Last name is required', trigger: [ 'change', 'blur' ] }
      ]">
      <el-input placeholder="E.g: Wong"
        v-model.trim="localForm.lastName"
        autocomplete="family-name"
        name="roomLeadGuestLastName"
        @blur="handleBlur"
        :disabled="isUsePrimaryGuestAsLocalForm"/>
    </el-form-item>
    <el-form-item
      label="Nationality"
      prop="nationality"
      class="guest-from--nationality"
      :rules="[
        { required: true, message: 'Nationality is required', trigger: [ 'change', 'blur' ] }
      ]"
      >
      <el-select
        filterable
        autocomplete="no"
        placeholder="Nationality"
        v-model="localForm.nationality"
        name="roomLeadNationality"
        >
        <el-option v-for="it in countries" :key="it.a3" :value="it.a2" :label="it.lb" />
      </el-select>
    </el-form-item>
    <el-form-item
      class="guest-from--remarks"
      label="Remarks (optional)"
      prop="remarks"
      :rules="[
        { type: 'string', max: 250, message: 'Your room remarks is more than 250 characters, please shorten it.', trigger: [ 'change', 'blur' ]}
      ]">
      <el-input type="textarea" placeholder="E.g: non-smoking room (Limit 250 characters)"
        :autosize="{ minRows: 2, maxRows: 5}"
        v-model.trim="localForm.remarks"
        @blur="handleBlur" />
        <p class="form-help-text">Special/accessibility requests are not guaranteed and may incur additional charges.</p>
    </el-form-item>
  </el-form>
</template>

<script>
import countries from '../plugins/countries'

export default {
  name: 'CheckoutFormRoomLeadGuestForm',
  props: {
    value: Object | undefined,
    primaryGuest: {
      type: Boolean,
      default: false
    },
    primaryRoom: Object
  },
  data () {
    return {
      countries,
      usePrimaryGuest: true,
      localForm: {
        nationality: this.value.nationality,
        firstName: this.value.firstName,
        lastName: this.value.lastName,
        remarks: this.value.remarks
      }
    }
  },
  computed: {
    isUsePrimaryGuestAsLocalForm () {
      return !this.primaryGuest && this.usePrimaryGuest
    }
  },
  watch: {
    'value' (nV, oV) {
      this.localForm = nV
    },
    'primaryRoom': 'setPrimaryGuestToLocalForm'
  },
  methods: {
    validateForm () {
      return new Promise((resolve, reject) => {
        this.$refs.localForm.validate((valid, fields) => {
          resolve({ valid, fields })
        })
      })
    },
    setPrimaryGuestToLocalForm () {
      if (this.usePrimaryGuest) {
        this.localForm = {
          ...this.localForm,
          nationality: this.primaryRoom.nationality,
          firstName: this.primaryRoom.firstName,
          lastName: this.primaryRoom.lastName
        }
        this.$emit('input', { ...this.localForm })
      }
    },
    handleBlur () {
      this.$emit('input', { ...this.localForm })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .guest-from--nationality {
    width: 47%;
    .el-select {
      width: 100%;
    }
  }
  .guest-from--use-primary-guest {
    width: 100%;
  }
  .guest-from--guest-first-name {
    width: 55%;
  }
  .guest-from--guest-last-name {
    width: 40%;
  }
  .guest-from--remarks {
    width: 100%;
  }
  @include mobile {
    .guest-from--nationality {
      width: 100%;
    }
    .guest-from--use-primary-guest {
      width: 100%;
    }
    .guest-from--guest-first-name {
      width: 100%;
    }
    .guest-from--guest-last-name {
      width: 100%;
    }
  }
</style>
