<template>
  <div class="section">
    <div class="container is-desktop">
      <div class="columns">
        <div class="column is-12 has-text-centered">
          <OrderMessage
            :order="order"
            :payment="payment"/>
        </div>
      </div>
      <div class="columns component-wrapper">
        <div class="column is-8 is-offset-2 form-component">
          <div class="box is-stick">
            <progress-indicator class="wl-progress-bar--color" name="booking-progress"></progress-indicator>
            <div class="list is-topless is-bottomless has-background-white-bis">
              <div class="list-content">
                <div class="list-value has-text-grey">
                  <el-tooltip content="Your order code">
                    <span>
                      <i class="el-icon-document" /> {{ order.orderReference }}
                    </span>
                  </el-tooltip>
                </div>
              </div>
              <div class="list-action">
                <el-button
                  @click="handlePrintButtonClicked"
                  type="text"
                  class="is-size-5"
                  icon="el-icon-printer">
                </el-button>
              </div>
            </div>
          </div>
          <div class="box is-stick">
            <div class="list is-topless is-bottomless">
              <div class="list-content">
                <div class="list-value has-text-grey">Booking status</div>
              </div>
              <div class="list-action is-uppercase">{{ order.status && order.status.label }}</div>
            </div>
          </div>
          <div class="box is-stick">
            <div class="box-content">
              <OrderProperty v-if="property.name" v-bind="property" />
            </div>
          </div>
          <div class="box is-stick">
            <OrderDetails v-if="order.package" class="box-content form-content" v-bind="order.package" :nights="nights"/>
          </div>
          <div class="box is-stick"
            v-for="(it, idx) in order.package && order.package.roomLeadGuests"
            :key="it.firstName + idx">
            <div class="box-content" style="padding-top: 12px; padding-bottom: 12px;">
              <div class="list is-comfort is-gapless wrap">
                <div class="list-pre list-is-12-mobile" style="min-width: 80px;">
                  <div class="list-value has-text-weight-semibold">Room {{idx + 1}}</div>
                </div>
                <div class="list-content list-is-12-mobile">
                  <!-- <small>TODO: UI need to change</small> -->
                  <div class="list-label">Reserved for: </div>
                  <div class="list-value has-text-weight-semibold">
                    {{ it.firstName }} {{ it.lastName }}
                    <span v-show="it.nationality" class="">({{ it.nationality }})</span>
                  </div>
                </div>
                <div class="list-content list-is-12-mobile">
                  <div class="list-label">Remarks*</div>
                  <div class="list-value">
                    {{ it.remarks || '–' }}
                  </div>
                </div>
              </div>
              <p class="form-help-text" style="padding-left: 0;">*Special/accessibility requests are not guaranteed and may incur additional charges.</p>
            </div>
          </div>
          <div class="box is-stick">
            <div class="box-header"><div class="has-text-weight-semibold has-text-dark">Cancellation policy</div></div>
            <div class="box-content">
              <div class="list is-gapless wrap is-topless">
                <CancellationParse
                  class="list-content list-is-12-mobile"
                  :freeCancellationBefore="order.package && order.package.freeCancellationBefore" />
                <div class="list-action">
                  <el-button
                    v-if="isCancellable"
                    type="primary"
                    @click="cancellationProcessVisibility = true">
                    Cancel booking
                  </el-button>
                </div>
              </div>
              <!-- <p class="is-size-7 has-text-weight-semibold">Remark</p> -->
              <div class="list is-gapless is-comfort" style="padding-bottom: 0;">
                <div class="content is-small" v-if="order.package && order.package.cancellationRemarks" v-html="order.package.cancellationRemarks"></div>
              </div>
              <p v-if="isCancellable" class="is-size-7">
                A charge of <span class="has-text-weight-semibold has-text-dark">{{ charge.totalChargeAmount.txFees | currency({ currency: charge.totalChargeAmount.currency }) }}</span> will be imposed uppon cancellation.
              </p>
            </div>
          </div>
          <div class="box is-stick">
            <div class="box-header"><div class="has-text-weight-semibold has-text-dark">Additional rules and restrictions</div></div>
            <div class="box-content">
              <div class="content is-small"
                v-if="order.package && order.package.checkInInstructions"
                v-html="order.package.checkInInstructions"></div>
              <div class="list is-small is-gapless">
                <div class="list-content">
                  <div class="list-label">Check-in time</div>
                  <div>{{ property.checkInTime || '–' }}</div>
                </div>
                <div class="list-content">
                  <div class="list-label">Check-out time</div>
                  <div>{{ property.checkOutTime || '–' }}</div>
                </div>
                <!-- <div class="list-content">
                  <div class="list-label">Payment type</div>
                  <div>{{ property.payments || '–' }}</div>
                </div> -->
                <div class="list-content">
                  <div class="list-label">Nearest airport</div>
                  <div>{{ property.airportCode || '–' }}</div>
                </div>
              </div>
              <div class="gap"></div>
              <div class="content is-small">
                <p>
                  <b>Please note that hotels update their internal records (such as guest name, room type, booking number, etc.) any time from the moment a booking is made until the date of check-in. Please do not hesitate to contact us if you have any queries regarding your booking.</b>
                </p>
              </div>
            </div>
          </div>
          <div class="box has-background-white-bis">
            <div class="box-header">
              <div class="has-text-weight-semibold has-text-dark"
                v-if="payment.captured && !payment.refunded">
                <i class="el-icon-check has-text-success" /> Your booking has been paid in full
              </div>
              <div class="has-text-weight-semibold has-text-dark"
                v-if="payment.refunded">
                <i class="el-icon-check has-text-success" /> Refunded
              </div>
            </div>
            <div class="box-content">
              <div class="list is-comfort is-gapless is-align-flex-start">
                <div class="list-content">
                  <div class="">{{ pckg.supplierDescription }}</div>
                </div>
                <div class="list-middle">
  x&nbsp;<span class="list-value has-text-weight-semibold">{{ pckg.roomCount }}</span>
                </div>
                <div class="list-action has-text-right">
                  <div class="list-value has-text-weight-semibold">
                    {{ charge.totalChargeAmount | currencyObj }}
                  </div>
                  <div class="list-label">(Taxes and fees included)</div>
                </div>
              </div>
              <div class="list is-comfort is-gapless has-border-top">
                <div class="list-content">
                </div>
                <div class="list-action has-text-right">
                  <span class="list-value has-text-weight-semibold">Total charged:</span>
                </div>
                <div class="list-action">
                  <span class="list-value has-text-weight-semibold">{{ charge.totalChargeAmount | currencyObj }}</span>
                </div>
              </div>
              <div class="list is-narrow is-gapless is-topless"
                v-if="payment.refunded">
                <div class="list-content">
                </div>
                <div class="list-action has-text-right">
                  <span class="list-value has-text-weight-semibold has-text-success">Total ammount refunded:</span>
                </div>
                <div class="list-action">
                  <span class="list-value has-text-weight-semibold has-text-success">{{ payment.amountRefunded | currencyObj }}</span>
                </div>
              </div>
              <p class="is-size-7 has-text-right" v-if="cancelledOrder">*Refund process takes approximately 5-10 business.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PpModal
      v-model="cancellationProcessVisibility">
      <CancellationProcess
        v-if="order.package"
        :freeCancellationBefore="order.package.freeCancellationBefore"
        :cancellationRemarks="order.package.cancellationRemarks"
        :ordToken="$route.params.ordToken"
        @done="handleCancellationDone"
        @closed="cancellationProcessVisibility = false"/>
    </PpModal>
  </div>
</template>

<script>
import lv2, { axiosErrorHandler } from '../lv2Api'
import store from '../store'
import OrderMessage from './OrderMessage'
import OrderProperty from './OrderProperty'
import OrderDetails from './OrderDetails'
import CancellationParse from '../components/CancellationParse'
import CancellationProcess from './CancellationProcess'

const asyncData = async ({ params }) => {
  try {
    const { data } = await lv2.get(`/order/${params.ordToken}`)
    const { outlets, meta } = data
    store.setMetaAction(meta)
    return outlets
  } catch (err) {
    const data = axiosErrorHandler(err)
    if (data.meta) store.setMetaAction(data.meta)
    // if (data.error && data.error.code === 'ck_token_expired') throw data.error
    store.setGloablModal({ show: true, content: data.message })
    throw err
  }
}

export default {
  name: 'OrderComfirmation',
  components: {
    [OrderMessage.name]: OrderMessage,
    [OrderProperty.name]: OrderProperty,
    [OrderDetails.name]: OrderDetails,
    [CancellationParse.name]: CancellationParse,
    [CancellationProcess.name]: CancellationProcess
  },
  data () {
    return {
      isCancellable: false,
      isContinuePulling: null,

      cancellationProcessVisibility: false,

      nights: 1,
      property: {},
      order: {},
      payment: {}
    }
  },
  computed: {
    pckg () {
      return this.order.package || {}
    },
    charge () {
      return this.order.charge || {}
    },
    cancelledOrder () {
      return this.order.status && this.order.status.code === 'ord-cx'
    }
  },
  async beforeRouteEnter (to, from, next) {
    try {
      const { property, order, payment, nights, isCancellable, isContinuePulling } = await asyncData({ params: to.params })
      next(vm => {
        vm.isCancellable = isCancellable
        vm.isContinuePulling = isContinuePulling

        vm.property = property
        vm.order = order
        vm.payment = payment
        vm.nights = nights
      })
    } catch (err) {
      next()
    }
  },
  watch: {
    'isContinuePulling': {
      immediate: true,
      handler (nV, oV) {
        if (nV) {
          this.$progress.show('booking-progress')
          setTimeout(() => this.polling(), 5000)
        }
        if (nV === false) {
          this.$progress.hide('booking-progress')
        }
      }
    }
  },
  methods: {
    handlePrintButtonClicked () {
      window.print()
    },
    handleCancellationDone (outlets) {
      const { order, isCancellable, isContinuePulling } = outlets
      console.log(order)
      this.order = { ...this.order, ...order }
      this.isCancellable = isCancellable
      this.isContinuePulling = isContinuePulling
      this.cancellationProcessVisibility = false
      this.polling()
      window.scrollTo(0, 0)
    },
    handleCancellationModalOpened () {},
    async polling () {
      this.isContinuePulling = null // this will trigger the watch
      try {
        const {
          property,
          order,
          payment,
          nights,
          isCancellable,
          isContinuePulling } = await asyncData({ params: this.$route.params })

        this.isCancellable = isCancellable
        this.isContinuePulling = isContinuePulling

        this.property = property
        this.order = order
        this.payment = payment
        this.nights = nights
      } catch (err) {

      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .section {
    padding-top: $bleed * 3;
  }

  .component-wrapper {
    @include mobile {
      display: flex;
      flex-direction: column;
    }
  }
  .form-component {
    @include mobile {
      margin-left: -$column-gap;
      margin-right: -$column-gap;
      order: 1;
      // width: 100%;
    }
  }
  .summary-component {
    @include mobile {
      padding: $bleed;
      order: 0;
      // width: 100%;
    }
  }
  .pop-image {
    position: absolute;
    top: 0;
    right: 0;
    height: 200px;
    width: 200px;
  }
</style>
