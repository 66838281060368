import { render, staticRenderFns } from "./OrderProperty.vue?vue&type=template&id=23b208a6&scoped=true&"
import script from "./OrderProperty.vue?vue&type=script&lang=js&"
export * from "./OrderProperty.vue?vue&type=script&lang=js&"
import style0 from "./OrderProperty.vue?vue&type=style&index=0&id=23b208a6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b208a6",
  null
  
)

component.options.__file = "OrderProperty.vue"
export default component.exports