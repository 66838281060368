<template>
  <StarAward v-if="singleMode && limited[0] && limited[0].badgeData.categoryId !== '16ac'">
    <span slot="prepend">Excellent</span>
    <span slot="title">{{limited[0].badgeData.categoryName}}</span>
    Top {{ Math.floor(limited[0].badgeData.popularity) }}%
  </StarAward>
  <div v-else class="columns">
    <div class="column is-narrow"
      v-for="it in limited"
      :key="it.badgeData.categoryId">
      <StarAward v-if="it.badgeData.categoryId !== '16ac'">
        <span slot="prepend">Excellent</span>
        <span slot="title">{{it.badgeData.categoryName}}</span>
        Top {{ Math.floor(it.badgeData.popularity) }}%
      </StarAward>
    </div>
  </div>
</template>

<script>
import StarAward from './StarAward'

export default {
  name: 'TrustYouBadgeList',
  components: {
    [StarAward.name]: StarAward
  },
  props: {
    badgeList: {
      type: Array,
      default () {
        return []
      }
    },
    limit: {
      type: Number,
      default: 3
    },
    singleMode: {
      type: Boolean,
      default: false
    },
    popularityThreshold: {
      type: Number,
      default: 11
    }
  },
  computed: {
    limited () {
      // Filter badgeData with categoryId and popularity between 0 < x < 11
      return this.badgeList.filter(({ badgeData } = {}) => badgeData.categoryId && badgeData.popularity < this.popularityThreshold && badgeData.popularity > 0).splice(0, this.limit)
    }
  }
}
</script>

<style>

</style>
