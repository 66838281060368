<template>
  <el-form :inline="true" label-position="top" :model="localForm"
    ref="localForm" status-icon>
    <el-form-item
      class="payment-from--name"
      label="Name"
      prop="name"
      :rules="[
        { required: true, message: 'Name is required', trigger: [ 'change', 'blur' ] }
      ]">
      <el-input
        name="paymentName"
        placeholder="E.g: ALICE WONG" v-model="localForm.name"
        autocomplete="cc-name"
        />
    </el-form-item>
    <div class="divider"></div>
    <div class="gap-x2"></div>
    <el-form-item
      :error="errorMessage"
      style="width: 100%">
      <div id="card-element" ref="card"></div>
      <!-- <div class="el-form-item__error payment-error-message">{{ errorMessage }}</div> -->
    </el-form-item>
  </el-form>
  <!-- <div class="el-form-item">
    <div class="el-form-item__content">
      <div id="card-element"></div>
      <div class="el-form-item__error payment-error-message">{{ errorMessage }}</div>
    </div>
  </div> -->
</template>

<script>
/**
 * Setup custom font
 */
const fonts = [{
  cssSrc: 'https://use.typekit.net/atr1ocu.css'
}]

/**
 * Style, recomanded to use style instead of class coz it's served from IFrame
 */
const style = {
  base: {
    fontFamily: '"myriad-pro",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei",Arial,sans-serif',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '40px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: '#c0c4cc'
    }
  }
}

const classes = {
  base: 'payment-base',
  invalid: 'payment-invalid',
  complete: 'payment-complete'
}

export default {
  name: 'StripePayment',
  data () {
    return {
      stripe: '', // inject Stripe instance later
      elements: undefined, // Stripe elements
      card: undefined, // Strip cardb
      errorMessage: '', // Store strip error message
      localForm: {
        name: ''
      }
    }
  },
  mounted () {
    this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY, {
      betas: ['payment_intent_beta_3']
    })
    this.elements = this.stripe.elements({ fonts })
    this.card = this.elements.create('card', { style, classes })

    this.card.mount('#card-element')

    this.card.addEventListener('change', ({ error }) => {
      this.errorMessage = error ? error.message : ''
    })
  },
  methods: {
    validateForm () {
      return new Promise((resolve, reject) => {
        this.$refs.localForm.validate((valid, fields) => {
          resolve({ valid, fields })
        })
      })
    },
    /**
     * Deprecated: Use 'handleCardPayment()' func below.
     * Stripe recomanded us to use PaymentIntent instead from 2018-12-11
     */
    async createToken () {
      const { token, error } = await this.stripe.createToken(this.card, {
        name: this.localForm.name
      })
      console.log(error)
      this.errorMessage = error ? error.message : ''
      return error ? { valid: false, fields: error } : { valid: true, token }
    },
    /**
     * New Stripe API using paymentIntent.
     * It support Dynamic 3DS.
     * @param clientSecret <string> - secrect generated by server.
     */
    async handleCardPayment (clientSecret) {
      try {
        const { paymentIntent, error } = await this.stripe.handleCardPayment(clientSecret, this.card, {
          source_data: {
            owner: {
              name: this.localForm.name
            }
          }
        })
        this.errorMessage = error ? error.message : ''
        this.$refs.card.classList.remove('payment-complete')
        this.$refs.card.classList.add('payment-invalid')
        return error ? { valid: false, fields: error } : { valid: true, paymentIntent }
      } catch (error) {
        this.errorMessage = error ? error.message : ''
        this.$refs.card.classList.remove('payment-complete')
        this.$refs.card.classList.add('payment-invalid')
        return { valid: false, fields: error }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .payment-from--name {
    width: 75%;
  }
  @include mobile {
    .payment-from--name {
      width: 100%;
    }
  }
  // TODO: put it into variable
  .payment-base {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: $text;
    display: block;
    font-size: inherit;
    outline: none;
    padding: 0px 15px;
    width: 100%;
    height: 40px;
    line-height: 40px;
  }
  .payment-invalid {
    border-color: $danger;
  }
  .payment-complete {
    border-color: $success;
  }
  .payment-invalid + .payment-error-message {
    transform: translateY(0);
    opacity: 1;
  }
  .payment-error-message {
    transition: transform 100ms ease-out;
    transform: translateY(-10px);
    opacity: 0;
  }
</style>
