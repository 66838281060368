<template>
  <div>
    <div>
      <div class="is-background-image" style="height: 120px;" v-lazy:background-image="property.heroImage.url">
      </div>
      <PpGap size="small" />
      <div class="list is-gapless is-narrow is-topless is-align-flex-start is-bottomless">
        <div class="list-content has-line-height-1-25">
          <h4 class="is-size-6 has-text-black-ter has-text-weight-semibold has-line-height-1-25">
            <span style="letter-spacing: -0.5px; vertical-align: middle;">{{ property.name }}&nbsp;</span>
            <StarRatings
              class="is-inline-block is-size-7"
              :ratings="property.starRating" />
          </h4>
          <PropertyAddress
             class="is-size-7"
            :location="property.location" />
        </div>
        <div class="list-action">
          <TrustYouRating
            v-bind="property.trustYou"
            :showReviewsCount="false"
            size="s" />
        </div>
      </div>
    </div>
    <ZuDivider />
    <div class="list is-gapless is-narrow is-topless is-bottomless">
      <div class="list-content">Check-in date</div>
      <div class="list-action"><span class="has-text-weight-semibold has-text-dark">{{ searchQuery.checkInDate | date({ year: 'numeric', month: 'short', day: 'numeric', weekday: 'short' }) }}</span></div>
    </div>
    <div class="list is-gapless is-narrow is-topless">
      <div class="list-content">Check-out date</div>
      <div class="list-action has-text-right">
        <span class="has-text-weight-semibold has-text-dark">{{ searchQuery.checkOutDate | date({ year: 'numeric', month: 'short', day: 'numeric', weekday: 'short' }) }}</span>

      </div>
    </div>
    <div class="list is-gapless is-narrow is-topless">
      <div class="list-content">Adults</div>
      <div class="list-middle">
        x
      </div>
      <div class="list-action"><span class="has-text-weight-semibold has-text-dark">{{ searchQuery.adultCount }}</span></div>
    </div>
    <div class="list is-gapless is-narrow is-topless is-bottomless">
      <div class="list-content">
        Children
        <span class="list-label" v-show="children.length > 0">(Ages: {{ searchQuery.children || 0 }})</span>
      </div>
      <div class="list-middle">
        x
      </div>
      <div class="list-action"><span class="has-text-weight-semibold has-text-dark">{{ children.length || 0 }}</span></div>
    </div>
    <ZuDivider />
    <div class="list is-gapless wrap">
      <div class="list-content">
        <div class="list-label">Room description</div>
        <div><span class="has-text-weight-semibold has-text-dark">{{ pckg.supplierDescription }}</span></div>
      </div>
      <div class="list-action has-text-right" style="padding-right: 0;">
        <div class="list-label">(per night, per room)</div>
        <span class="has-text-weight-semibold has-text-dark">
          {{ pckg.unitAdjustedDisplayRate | currencyObj }}
        </span>
      </div>
      <div class="list-content list-fullwidth is-size-7">
        <div>• {{ pckg.foodCode | foodCode }}</div>
        <PpGap size="small" />
        <CancellationParse :freeCancellationBefore="pckg.cancellationPolicy && pckg.cancellationPolicy.freeCancellationBefore" />
        <p></p>
      </div>
    </div>
    <PpGap />
    <div class="list is-gapless is-narrow is-topless ">
      <div class="list-content">Nights</div>
      <div class="list-middle">
        x
      </div>
      <div class="list-action has-text-right">
        <span class="has-text-weight-semibold has-text-dark">{{ searchQuery.checkInDate | countDays({ endDate: searchQuery.checkOutDate, round: 'down'}) }}</span>
      </div>
    </div>
    <div class="list is-gapless is-narrow is-topless is-bottomless">
      <div class="list-content">Room</div>
      <div class="list-middle">
        x
      </div>
      <div class="list-action"><span class="has-text-weight-semibold has-text-dark">{{ searchQuery.roomCount }}</span></div>
    </div>
    <ZuDivider />
    <!-- <div class="list is-gapless is-narrow is-topless ">
      <div class="list-content">Sub total:</div>
      <div class="list-middle"></div>
      <div class="list-action has-text-right">
        <span class="has-text-dark">
          {{ subTotal | currency({ currency: pckg.totalAmount.currency }) }}
        </span>
      </div>
    </div>
    <ZuDivider /> -->
    <!-- <div class="list is-gapless is-narrow">
      <div class="list-content has-text-right" style="align-self: flex-start;">
        <span class="has-text-weight-semibold has-text-dark">Total amount:</span>
      </div>
      <div class="list-action has-text-left">
        <span class="has-text-weight-semibold has-text-dark is-size-5">{{ pckg.totalAmount | currencyObj }}</span>
      </div>
    </div> -->
    <table class="total-table">
      <tbody>
        <tr>
          <td class="has-text-right"><span class="has-text-weight-semibold has-text-dark">Total amount:</span></td>
          <td><span class="has-text-weight-semibold has-text-dark is-size-5">{{ pckg.totalAmount | currencyObj }}</span></td>
        </tr>
        <tr v-if="pckg.totalAmount.taxesAndFeesTotal > 0">
          <td class="total-table-sub">Taxes</td>
          <td class="total-table-sub">{{ pckg.totalAmount.taxesAndFeesTotal | currency({ currency: pckg.totalAmount.currency }) }} incld.
          </td>
        </tr>
        <tr>
          <td class="total-table-sub">Fees</td>
          <td class="total-table-sub">{{ pckg.totalAmount.txFees | currency({ currency: pckg.totalAmount.currency }) }} incld.
          </td>
        </tr>
      </tbody>
    </table>
    <PpGap />
    <div
      v-if="pckg.cancellationPolicy && pckg.cancellationPolicy.freeCancellationBefore"
      class="list is-gapless is-narrow">
      <p class="is-size-7">
        A charge of <span class="has-text-weight-semibold has-text-dark">{{ pckg.totalChargeAmount.txFees | currency({ currency: pckg.totalChargeAmount.currency }) }}</span> will be imposed uppon cancellation.
      </p>
    </div>
    <div
      v-if="pckg.totalAmount"
      v-show="pckg.totalAmount.currency !== pckg.totalChargeAmount.currency"
      class="list is-gapless is-narrow">
      <p class="has-text-danger is-size-7">
        The full amount of the reservation will be charged in <span class="has-text-weight-semibold has-text-dark">{{ pckg.totalChargeAmount | currencyObj }} ({{  pckg.totalChargeAmount.currency }})</span> to the credit or debit card provided.
      </p>
    </div>
    <div class="hotelfees-warpper"
      v-if="pckg.hotelFees">
      <ZuDivider />
      <div class="list is-gapless">
        <div class="list-title has-text-weight-semibold">Taxes and fees due at property</div>
      </div>
      <div class="list is-gapless is-narrow is-topless is-small"
        v-for="(val, key) in pckg.hotelFees"
        :key="key">
        <div class="list-content">{{ key }}</div>
        <div class="list-action"><span class="has-text-weight-semibold has-text-dark">{{ val | currencyObj }}</span></div>
      </div>
      <p class="is-size-7">
        Taxes and Fees due at the property are based on current exchange rates, and are payable in local currency.
      </p>
    </div>
    <div class="gap"></div>
  </div>
</template>

<script>
import StarRatings from '@/components/StarRatings'
import CancellationParse from '@/components/CancellationParse'
import TrustYouRating from '@/components/TrustYouRating'
import PropertyAddress from '@/components/PropertyAddress'

export default {
  name: 'CheckoutFormSummary',
  components: {
    [StarRatings.name]: StarRatings,
    [CancellationParse.name]: CancellationParse,
    [PropertyAddress.name]: PropertyAddress,
    [TrustYouRating.name]: TrustYouRating
  },
  props: {
    searchQuery: Object,
    pckg: Object,
    property: Object
  },
  computed: {
    subTotal () {
      return this.pckg.totalAmount.value - this.pckg.totalAmount.taxesAndFeesTotal - this.pckg.totalAmount.txFees
    },
    children () {
      return this.searchQuery.children ? this.searchQuery.children.split(',') : 0
    }
  }
}
</script>

<style lang="scss">
  .total-table {
    width: 100%;
    border: 0;
    td {
      text-align: right;
    }
    .total-table-sub {
      font-size: 12px;
    }
  }
</style>
