<template>
  <div>
    <i class="is-size-2 el-icon-success has-text-dark" />
    <div class="gap"></div>
    <h3 class="title is-4 has-text-weight-semibold has-text-dark">
      We are processing your cancellation.
    </h3>
    <p class="subtitle is-6">
      Please wait...
    </p>
  </div>
</template>

<script>
export default {
  name: 'TransactionStatusCancelling',
  props: {
    order: Object,
    payment: Object
  }
}
</script>

<style>

</style>
