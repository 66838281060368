<template>
  <div class="box"
    style="min-width: 280px;"
    slot="content">
    <h5
      v-if="cancellationStep === 1 || cancellationStep === 2"
      class="box-header is-size-5 has-text-weight-semibold has-text-black-ter">
      Cancellation confirmation</h5>
    <div class="box-content">
      <transition name="view-fade-slow" mode="out-in">
        <div
          v-if="cancellationStep === 1"
          key="step-1">
          <CancellationParse
            class="list-content list-is-12-mobile"
            :freeCancellationBefore="freeCancellationBefore" />
          <div class="list is-gapless" style="padding-bottom: 0;">
            <div class="content is-small"  v-html="cancellationRemarks"></div>
          </div>
          <div class="gap-x2"></div>
          <p class="is-size-7 has-text-weight-semibold has-text-dark">Do you want to proceed?</p>
          <div class="gap"></div>
          <div class="list is-gapless is-bottomless">
            <div class="list-content"></div>
            <div class="list-action">
              <el-button type="text"
                @click="handleCancellationModalClosed">No</el-button>
              <el-button size="small" type="primary"
                @click="cancellationStep++">Yes, cancel now</el-button>
            </div>
          </div>
        </div>
        <div
          v-if="cancellationStep === 2"
          key="step-2"
          class="form-content" style="padding: 0;">
          <h5 class="has-text-weight-semibold has-text-dark subtitle is-7 is-marginless">For security purpose, please tell us the same mobile no., which used to make this booking.</h5>
          <el-form label-position="top"
            ref="cancellationForm"
            :model="cancellationForm"
            @submit.native.prevent="handlePostCancel">
            <el-form-item label="Mobile phone no." style="width: 90%;"
              prop="contactNo"
              :rules="[
                { type: 'string', message: 'Moile no. too short, min 6 characters.', trigger: [ 'change', 'blur' ], min: 6 },
                { type: 'string', message: 'Your mobile no. must contains only number', trigger: [ 'change', 'blur' ], pattern: /^[\d]+$/ig },
                { required: true, message: 'Your mobile no. is required', trigger: [ 'change', 'blur' ] }
              ]">
              <el-input type="tel" placeholder="E.g: 987890909" v-model="cancellationForm.contactNo"
              auto-complete="tel-national"></el-input>
              <p class="form-help-text"><i class="el-icon-bell has-text-primary" /> <i>Contact our customer support if you facing difficulty to continue.</i></p>
            </el-form-item>
          </el-form>
          <div class="gap-x2"></div>
          <div class="list is-gapless is-bottomless">
            <div class="list-content"></div>
            <div class="list-action">
              <el-button type="text"
                @click="handleCancellationModalClosed">Close</el-button>
              <el-button size="small" type="primary"
              :disabled="isCancelling"
              :loading="isCancelling"
              @click="handlePostCancel">Submit</el-button>
            </div>
          </div>
        </div>
        <div
          class="has-text-centered"
          v-if="cancellationStep === 3">
          <EmptyState
            :isLoading="isCancelling">
          </EmptyState>
          <p>Processing your cancellation request</p>
        </div>
        <!-- <div
          v-if="cancellationStep === 'succeeded'"
          class="has-text-centered"
          >
          <i class="is-size-2 el-icon-success has-text-success" />
          <div class="gap"></div>
          <h3 class="title is-6 has-text-weight-semibold has-text-success">
            Your booking is now cancelled.
          </h3>
          <div class="has-text-centered">
            <el-button @click="handleCancellationModalClosed">Close</el-button>
          </div>
        </div> -->
        <div
          v-if="cancellationStep === 'failed'"
          class="has-text-centered"
          >
          <i class="is-size-2 el-icon-error has-text-danger" />
          <div class="gap"></div>
          <h3 class="title is-6 has-text-weight-semibold has-text-danger">
            Ops! We are having dificullty to cancel your booking
          </h3>
          <div v-if="cancellationError">
            <p class="subtitle is-6"
              v-for="it in filterResolveActionType(cancellationError.resolveActions, 'message')"
              :key="it.label">{{ it.message }}</p>
              <PpGap />
          </div>
          <div class="has-text-centered">
            <!-- No idea but it works -->
            <el-button
              v-if="cancellationError && filterResolveActionType(cancellationError.resolveActions, 'button').find(ele => (ele.label == 'tryagainButton'))"
              type="text"
              @click="cancellationStep = 2">Try again</el-button>
            <el-button
              @click="handleCancellationModalClosed">Close</el-button>
          </div>
        </div>
      </transition>
      </div>
  </div>
</template>

<script>
import lv2, { axiosErrorHandler } from '../lv2Api'
import store from '../store'
import CancellationParse from '../components/CancellationParse'

const asyncData = async ({ body }) => {
  try {
    const { data } = await lv2.post(`/cancel/order`, body)
    const { outlets, meta } = data
    store.setMetaAction(meta)
    return outlets
  } catch (err) {
    const data = axiosErrorHandler(err)
    if (data.meta) store.setMetaAction(data.meta)
    if (data.error && data.error.type === 'invalid_request_error') throw data.error
    store.setGloablModal({ show: true, content: data.message })
    throw err
  }
}

export default {
  name: 'CancellationProcess',
  components: {
    [CancellationParse.name]: CancellationParse
  },
  props: [ 'freeCancellationBefore', 'cancellationRemarks', 'ordToken' ],
  data () {
    return {
      cancellationForm: {},
      isCancelling: false,
      cancellationStep: 1,
      cancellationError: undefined
    }
  },
  methods: {
    handleCancellationModalClosed () {
      this.$emit('closed')
      // Reset to inital state
      this.cancellationStep = 1
      this.cancellationForm.contactNo = undefined
      this.isShowCancellationModal = false
      this.isCancelling = false
    },
    filterResolveActionType (actions, type) {
      return actions.filter(e => (e.type === type))
    },
    postBody () {
      return {
        ordToken: this.ordToken,
        identity: this.cancellationForm
      }
    },
    handlePostCancel () {
      this.$refs.cancellationForm.validate(async (valid) => {
        if (!valid) return
        this.isCancelling = true
        this.cancellationForm.accessToken = this.accessToken
        this.cancellationStep = 3
        await asyncData({ body: this.postBody() })
          .then((outlets) => {
            this.cancellationStep = 'succeeded'
            return this.$emit('done', outlets)
          })
          .catch((err) => {
            this.cancellationStep = 'failed'
            this.cancellationError = err
            this.isCancelling = false
            console.error(err)
            return this.$emit('done')
          })
      })
    }
  }
}
</script>

<style>

</style>
