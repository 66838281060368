import { render, staticRenderFns } from "./StarAward.vue?vue&type=template&id=39d9bcf1&scoped=true&"
import script from "./StarAward.vue?vue&type=script&lang=js&"
export * from "./StarAward.vue?vue&type=script&lang=js&"
import style0 from "./StarAward.vue?vue&type=style&index=0&id=39d9bcf1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39d9bcf1",
  null
  
)

component.options.__file = "StarAward.vue"
export default component.exports