<template>
  <div class="sw--default-wrap">
    <IconBase
      class="sw--default sw--left"
      iconName="stars-award-left">
      <IconStarsAward />
    </IconBase>
    <div class="sw--content">
      <div class="sw--prepend"
        v-if="$slots.prepend">
        <slot name="prepend"/>
      </div>
      <div class="sw--title">
        <slot name="title"/>
      </div>
      <div class="sw--subtitle">
        <slot />
      </div>
    </div>
    <IconBase
      class="sw--default sw--right"
      iconName="stars-award-right">
      <IconStarsAward />
    </IconBase>
  </div>
</template>

<script>
import IconStarsAward from './icons/IconStarsAward'

export default {
  name: 'StarAward',
  components: {
    'IconStarsAward': IconStarsAward
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  // $leaf-color: #baa061;
  // $leaf-color: #e6c46a !default;
  $leaf-color: #ffca6f !default;
  $title-color: #003300 !default;
  .sw--default-wrap {
    display: inline-block;
    white-space: nowrap;
    color: $grey;
  }
  .sw--default /deep/ #stars-award-right,
  .sw--default /deep/ #stars-award-left {
    fill: $leaf-color;
    // fill: #f9980eed;
  }
  .sw--default {
    width: 4em;
    height: 4em;
    letter-spacing: -1px;
  }
  .sw--content {
    display: inline-block;
    text-align: center;
    line-height: 1.1;
    vertical-align: middle;
  }
  .sw--right {
    vertical-align: middle;
    margin-left: -1.6em;
  }
  .sw--left {
    transform: scaleX(-1);
    vertical-align: middle;
    margin-right: -1.6em;
  }
  .sw--subtitle {
    margin-top: 0.2em;
    margin-bottom: 0.5em;
    font-size: 0.85em;
    // color: $leaf-color;
    color: $title-color;
  }
  .sw--prepend {
    font-size: 0.75em;
    margin-bottom: 0.3em;
    color: brightness($title-color, 20%);
  }
  .sw--title {
    text-transform: uppercase;
    color: $title-color;
    font-size: 1.45em;
    font-weight: 600;
    min-width: 3em;
    font-family: 'Arial Narrow', sans-serif;
  }
</style>
