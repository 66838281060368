<template>
  <div class="">
    <div class="list is-comfort is-gapless">
      <div class="list-content">
        <div class="list-label">Check-in date</div>
        <div class="list-value has-text-weight-semibold">{{ checkInDate | date(dateFormat) }}</div>
        <div class="is-size-7">&nbsp;</div>
      </div>
      <div class="list-content">
        <div class="list-label">
          Check-out date
        <el-tooltip content="">
          <div slot="content">Check-in/Check-out date are <br >display at property local time</div>
          <i class="el-icon-info has-text-link" />
        </el-tooltip>
        </div>
        <div class="list-value has-text-weight-semibold">{{ checkOutDate | date(dateFormat) }}</div>
        <div class="is-size-7">{{ nights | nightLabel }}</div>
      </div>
    </div>
    <div class="list is-comfort is-gapless">
      <div class="list-content">
        <div class="list-label">Adult count</div>
        <div class="list-value has-text-weight-semibold">{{ adultCount }}</div>
      </div>
      <div class="list-content">
        <div class="list-label">Children</div>
        <div class="list-value has-text-weight-semibold">
          {{ childrenCount || '0' }} <span v-if="childrenAges && childrenAges.length > 0">(Ages: {{ childrenAges.join(',') || 0 }})</span>
        </div>
      </div>
    </div>
    <div class="list is-comfort is-gapless">
      <div class="list-content">
        <div class="list-label">Room description</div>
        <div class="list-value has-text-weight-semibold">{{ supplierDescription }} &nbsp;&nbsp;x&nbsp;&nbsp;  <span class="list-value has-text-weight-semibold">{{ roomCount }}</span></div>
        <div class="">{{ foodCode | foodCode }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderDetails',
  props: {
    nights: Number,
    checkInDate: String,
    checkOutDate: String,
    adultCount: Number,
    childrenCount: Number,
    childrenAges: Array,
    supplierDescription: String,
    roomCount: Number,
    foodCode: String
  },
  data () {
    return {
      dateFormat: {
        timeZone: 'UTC',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short'
      }
    }
  }
}
</script>

<style>

</style>
