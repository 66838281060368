<template>
  <div>
    <i class="is-size-2 el-icon-success has-text-success" />
    <div class="gap"></div>
    <h3 class="title is-4 has-text-weight-semibold has-text-success">
      Your booking has been confirmed.
    </h3>
    <p class="subtitle is-6">A confirmation email was sent to: <span class="has-text-weight-semibold">{{ order.contactPerson.email }}</span></p>
  </div>
</template>

<script>
export default {
  name: 'TransactionStatusConfirmed',
  props: {
    order: Object,
    payment: Object
  }
}
</script>

<style>

</style>
