<template>
  <div>
    <i class="is-size-2 el-icon-error has-text-danger" />
    <div class="gap"></div>
    <h3 class="title is-4 has-text-weight-semibold has-text-danger">
      Sorry, we are unable to process your booking.
    </h3>
    <p class="subtitle is-6">
      For more information please contact customer support.
      <br>
      <i class="el-icon-close" /> No payment has been processed.
    </p>
  </div>
</template>

<script>
export default {
  name: 'TransactionStatusBookingFailure',
  props: {
    order: Object,
    payment: Object
  }
}
</script>

<style>

</style>
