<template>
  <section>
  <div class="section is-small">
    <div class="container is-desktop">
      <h3 class="is-size-5 has-text-weight-semibold has-text-black-ter">
        Secure booking — only takes 2 minutes!
      </h3>
    </div>
  </div>
  <div class="section is-small">
    <div class="container is-desktop">
      <PpBreadcrumb
        class="is-size-7-mobile"
        :routes="breadcrumb" />
    </div>
  </div>
  <div class="section is-gapless">
    <div class="container is-desktop">
      <div class="component-wrapper is-clearfix">
        <div class="summary-component">
          <CheckoutFormSummary
            class="summary-component-content"
            v-if="property.name"
            :searchQuery="searchQuery"
            :property="property"
            :pckg="pckg"
            />
          <TrustYouBadgeList
            class="is-size-7 is-centered"
            :limit="5"
            :badgeList="reviews.badgeList"/>
        </div>
        <div class="form-component">
          <div class="box express-checkout"
            v-if="!mx_customer">
              <div class="list is-gapless wrap has-text-white">
                <div class="list-action">
                  <span class="is-size-6">Express checkout <i class="el-icon-d-arrow-right" /></span>
                </div>
                <div class="list-action"
                  v-if="lv2FacebookLoginEnabled">
                  <ContinueWithFacebook
                    class="fb-button"
                    :reff="reff" />
                </div>
                <div class="ist-action">
                  <router-link
                    class="el-button el-button--primary el-button--small"
                    :to="{ name: 'login', query: { reff } }">
                    Login
                  </router-link>
                </div>
            </div>
          </div>
          <div class="box is-stick">
            <h3 class="is-size-5 box-header has-text-weight-semibold has-text-black-ter">
              Room 1 (Primary guest)
            </h3>
            <div class="box-content form-content">
              <CheckoutFormRoomLeadGuestForm
                v-model="primaryRoomForm"
                :primaryGuest="true"
                ref="primaryRoomForm" />
            </div>
          </div>
          <div class="box is-stick"
            v-if="secondaryRoomCount"
            v-for="room in secondaryRoomCount"
            :key="'room_' + room + 1">
            <h3 class="is-size-5 box-header has-text-weight-semibold has-text-black-ter">
              Room {{room + 1}}
            </h3>
            <div class="box-content form-content">
              <CheckoutFormRoomLeadGuestForm
                v-model="secondaryRoomForms[room - 1]"
                :primaryRoom="primaryRoomForm"
                ref="secondaryRoomForms"/>
            </div>
          </div>
          <!-- <div class="box is-stick">
            <div class="box-content form-content">
            <p class="is-size-7">
              *Room remarks please include detail of your special/accessibility requests (e.g. Roll away beds, late check in, accessible bathroom). After booking, the property will be notified of your request to confirm availability. If confirmed by the property, you will receive a confirmation email. If you do not receive a confirmation email within 24 hours of the day that you made your booking, please contact the us directly to confirm or to help make alternative arrangements.
            </p>
            </div>
          </div> -->
          <div class="box">
            <h3 class="is-size-5 box-header has-text-weight-semibold has-text-black-ter">Contact person</h3>
            <div class="box-content form-content">
              <el-checkbox v-model="primaryGuestAsContactPerson"> Use primary guest as contact person</el-checkbox>
              <div class="gap"></div>
              <CheckoutFormContactPerson
                v-model="contactPersonForm"
                :primaryRoom="primaryRoomForm"
                :primaryGuestAsContactPerson="primaryGuestAsContactPerson"
                ref="contactPersonForm"/>
            </div>
          </div>
          <div class="box">
            <h3 class="is-size-5 box-header has-text-weight-semibold has-text-black-ter">
              Payment details
            </h3>
            <div class="box-content form-content">
              <StripePayment
                class="el-form"
                ref="paymentForm"/>
            </div>
          </div>
          <div class="box">
            <h3 class="is-size-5 box-header has-text-weight-semibold has-text-black-ter">Important information about your booking</h3>
            <div class="box-content form-content">
              <div
                class="content is-small"
                v-if="pckg.cancellationPolicy.remarks"
                v-html="pckg.cancellationPolicy.remarks" />
              <div class="content is-small">
                <p class="">By selecting to complete this booking I acknowledge that I have read and accept the <router-link
      :to="{ name: 'terms' }" target="_blank">Rules & Restrictions</router-link>,  <router-link
      :to="{ name: 'terms' }" target="_blank">Terms of Use</router-link>,  <router-link
      :to="{ name: 'terms' }" target="_blank">Privacy Policy</router-link> and <a>Government Travel Advice</a>.
                </p>
                <CancellationParse
                  :freeCancellationBefore="pckg.cancellationPolicy && pckg.cancellationPolicy.freeCancellationBefore"/>
              </div>
              <el-button
                size="big"
                type="success"
                :loading="isSubmiting"
                @click="handleSubmited"><span class="has-text-weight-semibold">Complete booking</span></el-button>
              <div class="gap-x2"></div>
              <p class="is-size-7 has-text-weight-semibold">
                We use secure transmission and encrypted storage to protect your personal information.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PpModal
      class="has-text-centered"
      v-model="submitModalVisibility"
      :isLoading="isSubmiting"
      loadingMessage="Requesting"
      @closed="handleSubmitModalClose">
      <div class="box" style="padding: 0;">
        <div class="box-content">
          <h5 class="is-size-5 has-text-weight-semibold has-text-black-ter">
            {{ postBookError.title || 'Booking error' }}
          </h5>
          <div class="list is-gapless">
            <div class="list-content">
              {{ postBookError.message }}
            </div>
          </div>
          <div class="gap-x2"></div>
          <div class="list is-gapless is-bottomless">
            <div class="list-content">
            </div>
            <div v-if="postBookError.resolveActions &&  postBookError.resolveActions.length > 0"
              class="list-action">
              <router-link
                tag="el-button"
                :class="[
                  idx === postBookError.resolveActions.length - 1 ? 'el-button--primary' : 'el-button--text',
                ]"
                v-for="(it, idx) in postBookError.resolveActions"
                replace
                :key="it.label"
                :to="{ path: it.relativeUrl }">
                  {{ it.label }}
              </router-link>
            </div>
            <div v-else
              class="list-action">
              <el-button
                type="primary"
                @click="submitModalVisibility = false">
                Ok
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </PpModal>
  </div>
  <PpGap size="large" />
  </section>
</template>

<script>
import smoothScroll from '../plugins/smoothScroll'
import ContinueWithFacebook from '../components/ContinueWithFacebook'
import EventBus from '../EventBus'
import lv2, { axiosErrorHandler } from '../lv2Api'
import { getCountryByAlpha2 } from '../plugins/countriesHelper'
import store from '../store'
import TrustYouBadgeList from '../components/TrustYouBadgeList'
import CancellationParse from '../components/CancellationParse'
import CheckoutFormSummary from './CheckoutFormSummary'
import CheckoutFormRoomLeadGuestForm from './CheckoutFormRoomLeadGuestForm'
import CheckoutFormContactPerson from './CheckoutFormContactPerson'
import StripePayment from '../components/StripePayment'

const asyncData = async ({ params, query }, source) => {
  try {
    const { data } = await lv2.get(`/checkout/${params.ckToken}`, {
      params: query
    })
    const { outlets, meta } = data
    store.setMetaAction(meta)
    return outlets
  } catch (err) {
    const data = axiosErrorHandler(err)
    if (data.meta) store.setMetaAction(data.meta)
    if (data.error && data.error.code === 'ck_token_expired') throw data.error
    store.setGloablModal({ show: true, content: data.message || data.error.message })
    throw err
  }
}

const postBook = async ({ body }) => {
  try {
    const { data } = await lv2.post(`/book`, body)
    const { outlets, meta } = data
    store.setMetaAction(meta)
    return outlets
  } catch (err) {
    const data = axiosErrorHandler(err)
    if (data.meta) store.setMetaAction(data.meta)
    if (data.error && data.error.type === 'invalid_request_error') throw data.error
    if (data.error && data.error.type === 'authorised_error') throw data.error
    store.setGloablModal({ show: true, content: data.message || data.error.message })
    throw err
  }
}

export default {
  name: 'CheckoutForm',
  components: {
    [ContinueWithFacebook.name]: ContinueWithFacebook,
    [CancellationParse.name]: CancellationParse,
    [TrustYouBadgeList.name]: TrustYouBadgeList,
    [CheckoutFormSummary.name]: CheckoutFormSummary,
    [CheckoutFormRoomLeadGuestForm.name]: CheckoutFormRoomLeadGuestForm,
    [CheckoutFormContactPerson.name]: CheckoutFormContactPerson,
    [StripePayment.name]: StripePayment
  },
  data () {
    return {
      primaryGuestAsContactPerson: true,
      primaryRoomForm: {},
      secondaryRoomForms: [],
      contactPersonForm: {},
      payment: {
        type: 'stripe',
        token: ''
      },

      submitModalVisibility: false,
      isSubmiting: false,
      postBookError: {},
      validate: [],

      searchQuery: {},
      searchParams: {},
      property: {},
      reviews: {},
      availability: { package: { cancellationPolicy: { } } },
      ckToken: '',
      backUrl: undefined
    }
  },
  computed: {
    breadcrumb () {
      if (this.backUrl == null) return []
      return [
        { route: { path: this.backUrl }, label: this.property.name, historyBack: true },
        { static: true, label: 'Checkout', hasSeperatorHidden: true }
      ]
    },
    secondaryRoomCount () {
      return this.searchQuery.roomCount - 1 || 0
    },
    pckg () {
      return this.availability.package || {}
    },
    reff () {
      return btoa(this.$route.fullPath)
    }
  },
  metaInfo () {
    return {
      title: `Checkout: ${this.property.name}`
    }
  },
  beforeRouteEnter (to, from, next) {
    const k = {
      params: to.params,
      query: to.query
    }
    asyncData(k)
      .then(({ availability, property, searchQuery, searchParams, ckToken, reviews = {}, backUrl, paymentType }) => {
        next(vm => {
          vm.property = property
          vm.searchQuery = searchQuery
          vm.searchParams = searchParams
          vm.availability = availability
          vm.payment.type = paymentType
          vm.ckToken = ckToken
          vm.reviews = reviews
          vm.backUrl = backUrl
          let k = 0
          // To create initial data base on roomCount
          while (k < vm.secondaryRoomCount) {
            vm.secondaryRoomForms.push({
              nationality: vm.lv2State.meta.country,
              firstName: '',
              lastName: '',
              remarks: ''
            })
            k++
          }
        })
      })
      .catch((err) => {
        console.error(err)
        next(vm => {
          if (err.code === 'ck_token_expired') {
            vm.postBookError = {
              title: 'Package expired',
              ...err
            }
            vm.submitModalVisibility = true
          }
        })
      })
  },
  // activated () {
  //   EventBus.$on('currency-changed', ({ currency } = {}) => {
  //     this.$router.replace({ name: 'property', query: { ...this.query, currency }, params: this.params }, () => this.$refs.factory.fetch())
  //   })
  // },
  // deactivated () {
  //   EventBus.$off('currency-changed')
  // },
  created () {
    EventBus.$on('currency-changed', ({ currency } = {}) => {
      this.$router.replace({ name: 'checkoutForm', query: { ...this.$route.query, currency }, params: this.params }, () => location.reload())
    })
    const ctry = getCountryByAlpha2(this.lv2State.meta.country)
    this.primaryRoomForm = {
      nationality: this.lv2State.meta.country,
      firstName: this.mx_customer && this.mx_customer.givenName,
      lastName: this.mx_customer && this.mx_customer.familyName
      // firstName: 'Ii',
      // lastName: 'Ninami',
      // remarks: 'More banklet please'
    }
    this.contactPersonForm = {
      telCountryCode: ctry,
      email: this.mx_customer && this.mx_customer.email,
      firstName: this.mx_customer && this.mx_customer.givenName,
      lastName: this.mx_customer && this.mx_customer.familyName
      // ...this.primaryRoomForm,
      // contactNo: '+123999282829',
      // email: 'ling.vir@gmail.com'
    }
    // const k = {
    //   params: this.$route.params,
    //   query: this.$route.query
    // }
    // asyncData(k)
    //   .then(({ availability, property, searchQuery, searchParams }) => {
    //     this.property = property
    //     this.searchQuery = searchQuery
    //     this.searchParams = searchParams
    //     this.availability = availability
    //     let k = 0
    //     while (k < this.secondaryRoomCount) {
    //       this.secondaryRoomForms.push({
    //         firstName: '',
    //         lastName: '',
    //         remarks: ''
    //       })
    //       k++
    //     }
    //   })
    //   .catch((err) => {
    //     console.error(err)
    //     if (err.code === 'ck_token_expired') {
    //       this.postBookError = {
    //         title: 'Package expired',
    //         ...err
    //       }
    //       this.submitModalVisibility = true
    //     }
    //   })
  },
  beforeDestroy () {
    EventBus.$off('currency-changed')
  },
  methods: {
    constructContactPersonForm (contactPersonForm) {
      const { telCountryCode, contactNo } = contactPersonForm
      return {
        ...contactPersonForm,
        contactNo: `${telCountryCode.ph}${contactNo}`
      }
    },
    constructPostBody () {
      return {
        ckToken: this.ckToken,
        pckgToken: this.availability.package.token,
        roomLeadGuests: [ this.primaryRoomForm, ...this.secondaryRoomForms ],
        contactPerson: this.constructContactPersonForm(this.contactPersonForm),
        payment: this.payment
      }
    },
    handleBack () {
      history.back()
    },
    handleSubmitModalClose () {
      smoothScroll.scrollTo('.is-error', { offset: -100 })
    },
    async handleSubmited () {
      this.postBookError = {}
      this.isSubmiting = true
      this.submitModalVisibility = true
      const { secondaryRoomForms, ...rest } = this.$refs
      const v = [].concat(secondaryRoomForms, ...Object.values(rest))
        .filter(v => v) // v => v remove undefined vNode

      try {
        const res = await Promise.all(v.map(vNode => vNode.validateForm()))
        const invalid = res.find(a => a.valid === false)
        if (invalid) {
          throw {
            type: 'submiting_error',
            code: 'invalid_form'
          }
        }
        /**
         * Deprecated on 2018-12-11, Instead of generate token and pass to server, we are adopting Strip paymentIntent API which suppport Dynamic3DS
         *
         * Generate Strip token
         * TODO: Handle unhnalde strip number
         * Invalid card number for testing
         * 3241 2313 1242 3423
         */
        // const createdToken = await this.$refs.paymentForm.createToken()
        // if (!createdToken.valid) {
        //   throw {
        //     type: 'submiting_error',
        //     code: 'fail_to_create_stripe_token'
        //   }
        // }

        // this.payment.token = createdToken.token
        /**
         * Generate Client secret from server
         */
        const { clientSecret, nextStep } = await postBook({ body: this.constructPostBody() })
        // const { nextStep } = await postBook({ body: this.constructPostBody() })
        const handlePayment = await this.$refs.paymentForm.handleCardPayment(clientSecret)
        if (!handlePayment.valid) {
          throw handlePayment.fields
        }
        this.nextStep(nextStep)
      } catch (err) {
        this.isSubmiting = false
        /**
         * Validation error
         */
        if (err.type === 'submiting_error') {
          this.postBookError = {
            title: 'Submiting error',
            code: 'submiting_error',
            message: 'To continue please correct the errors'
          }
          return
        }
        /**
         * Stripe error
         */
        if (err.type === 'card_error') {
          this.postBookError = {
            title: 'Payment error',
            code: err.code,
            message: err.message
          }
          return
        }

        if (['invalid_request_error', 'authorised_error'].includes(err.type)) {
          this.postBookError = {
            ...err
          }
          this.submitModalVisibility = true
        }
        /**
         * Post book response error
         */
        if (err.error) {
          this.postBookError = err.error
          return
        }
        console.error(err)
      }
    },
    nextStep ({ url, type }) {
      if (type === 'express') {
        this.$router.replace({ path: url })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  $summary-width: 330px;
  .express-checkout {
    color: $white;
    background: $grey;
    box-shadow: none;
    padding: $bleed * 2;
  }
  .fb-button {
    & /deep/ .login--fb-button {
      max-width: 200px;
    }
  }
  .form-component {
    @include tablet {
      margin-right: $summary-width + $bleed * 4;
    }
  }
  .summary-component {
    @include tablet {
      width: $summary-width;
      float: right;
    }
    @include mobile {
      .summary-component-content {
        padding: $bleed * 2 !important;
      }
    }
  }
</style>
