<template>
  <el-form :inline="true" label-position="top" :rules="formRules" :model="localForm"
  :status-icon="true"
    ref="localForm">
    <el-form-item
      label="Given name"
      prop="firstName"
      class="contact-person--first-name"
      :show-message="!primaryGuestAsContactPerson"
      >
      <el-input placeholder="E.g: Alice" v-model="localForm.firstName"
        name="contactPersonFirstName"
        autocomplete="given-name"
        :disabled="primaryGuestAsContactPerson"/>
    </el-form-item>
    <el-form-item
      label="Family name"
      prop="lastName"
      class="contact-person--last-name"
      :show-message="!primaryGuestAsContactPerson"
      >
      <el-input placeholder="E.g: Wong" v-model="localForm.lastName"
        name="contactPersonLastName"
        autocomplete="family-name"
        :disabled="primaryGuestAsContactPerson"/>
    </el-form-item>
    <el-form-item
      label="Country code"
      prop="telCountryCode"
      class="contact-person--tel-country-code"
      >
      <el-select
        filterable
        autocomplete="no"
        placeholder="country code"
        v-model="localForm.telCountryCode"
        name="contactPersonTelCountryCode"
        value-key="a3">
        <el-option value="" label="Select country code" />
        <el-option v-for="it in countries" :key="it.a3" :value="it" :label="it.lb + ' (' + it.ph + ')'" />
      </el-select>
    </el-form-item>
    <el-form-item
      label="Mobile phone number"
      prop="contactNo"
      class="contact-person--contact-no"
      >
      <el-input type="tel" placeholder="E.g: 987890909"
        name="contactPersonContactNo"
        autocomplete="tel-national"
        v-model="localForm.contactNo">
      </el-input>
      <p class="form-help-text">Please enter your real contact number for verification purpose.</p>
    </el-form-item>
    <el-form-item
      label="Email address"
      prop="email"
      class="contact-person--email"
      >
      <el-input type="email" placeholder="E.g: yourname@email.com"
        name="contactPersonEmail"
        autocomplete="email"
        v-model="localForm.email" />
      <p class="form-help-text">Please enter the email address where you would like to receive your confirmation.</p>
    </el-form-item>
  </el-form>
</template>

<script>
import countries from '../plugins/countries'
export default {
  name: 'CheckoutFormContactPerson',
  props: {
    value: {
      type: Object,
      required: true
    },
    primaryRoom: Object,
    primaryGuestAsContactPerson: Boolean
  },
  data () {
    return {
      countries,
      localForm: {
        telCountryCode: this.value.telCountryCode,
        firstName: this.value.firstname,
        lastsName: this.value.lastName,
        nationality: this.value.nationality,
        contactNo: this.value.contactNo,
        email: this.value.email
      },
      formRules: {
        firstName: [
          { type: 'string', message: 'Please type your first name in A-Z, 0-9', trigger: [ 'change', 'blur' ], pattern: /^[\w\d\s_-]+$/ig },
          { required: true, message: 'First name is required', trigger: [ 'change', 'blur' ] }
        ],
        lastName: [
          { type: 'string', message: 'Please type your last name in A-Z, 0-9', trigger: [ 'change', 'blur' ], pattern: /^[\w\d\s_-]+$/ig },
          { required: true, message: 'Last name is required', trigger: [ 'change', 'blur' ] }
        ],
        contactNo: [
          // { type: 'string', message: 'Your phone number must start with \'+\' follow by country code and number.', trigger: [ 'change', 'blur' ], pattern: /^\+[\d]+$/ig },
          { type: 'string', message: 'Invalid phone number', trigger: [ 'change', 'blur' ], pattern: /^\+?[1-9]\d{1,14}$/ig },
          { required: true, message: 'Phone number is required', trigger: [ 'change', 'blur' ] }
        ],
        email: [
          { type: 'email', message: 'Please type your correct email to receive booking status update.', trigger: [ 'change', 'blur', 'input' ] },
          { required: true, message: 'Email is required', trigger: [ 'change', 'blur' ] }
        ]
      }
    }
  },
  computed: {
  },
  watch: {
    'value': {
      immediate: true,
      handler (nV) {
        this.localForm = nV
      }
    },
    'primaryGuestAsContactPerson': 'usePrimaryGuestAsContactPerson',
    'primaryRoom': 'usePrimaryGuestAsContactPerson'
  },
  methods: {
    validateForm () {
      return new Promise((resolve, reject) => {
        this.$refs.localForm.validate((valid, fields) => {
          resolve({ valid, fields })
        })
      })
    },
    usePrimaryGuestAsContactPerson () {
      if (this.usePrimaryGuestAsContactPerson) {
        this.localForm = {
          ...this.localForm,
          firstName: this.primaryRoom.firstName,
          lastName: this.primaryRoom.lastName
        }
        this.$emit('input', { ...this.localForm })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .contact-person--tel-country-code {
    width: 47%;
    .el-select {
      width: 100%;
    }
  }
  .contact-person--first-name {
    width: 55%;
  }
  .contact-person--last-name {
    width: 40%;
  }
  .contact-person--contact-no {
    width: 42%;
  }
  .contact-person--email {
    width: 80%;
  }
  @include mobile {
  .contact-person--tel-country-code {
    width: 100%;
  }
  .contact-person--first-name {
    width: 100%;
  }
  .contact-person--last-name {
    width: 100%;
  }
  .contact-person--contact-no {
    width: 100%;
  }
  .contact-person--email {
    width: 100%;
  }
  }
</style>
