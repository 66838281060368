<template>
  <div>
    <i class="is-size-2 el-icon-warning has-text-info" />
    <div class="gap"></div>
    <h3 class="title is-4 has-text-weight-semibold has-text-info">
      Your booking is still pending
    </h3>
    <p class="subtitle is-6">You will notifed about booking status through <span class="has-text-weight-semibold">{{ order.contactPerson.email }}"</span>.</p>
  </div>
</template>

<script>
export default {
  name: 'TransactionStatusNotStarted',
  props: {
    order: Object,
    payment: Object
  }
}
</script>

<style>

</style>
