<template>
  <div>
    <i class="is-size-2 el-icon-success has-text-dark" />
    <div class="gap"></div>
    <h3 class="title is-4 has-text-weight-semibold has-text-dark">
      Your booking is now cancelled.
    </h3>
    <p class="subtitle is-6" v-if="payment.refunded">
      Your refund is currently being processed.
      <!-- <br>
      <span class="has-text-grey">This process takes approximately 5-10 business.</span> -->
    </p>
  </div>
</template>

<script>
export default {
  name: 'TransactionStatusCancelled',
  props: {
    order: Object,
    payment: Object
  }
}
</script>

<style>

</style>
