var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-form',{ref:"localForm",attrs:{"inline":true,"label-position":"top","model":_vm.localForm,"status-icon":true}},[(!_vm.primaryGuest)?_c('el-form-item',{staticClass:"guest-from--use-primary-guest"},[_c('el-checkbox',{on:{"change":_vm.setPrimaryGuestToLocalForm},model:{value:(_vm.usePrimaryGuest),callback:function ($$v) {_vm.usePrimaryGuest=$$v},expression:"usePrimaryGuest"}},[_vm._v(" Same as primary guest")])],1):_vm._e(),_c('el-form-item',{staticClass:"guest-from--guest-first-name",attrs:{"id":"kdoi","label":"Guest given name","prop":"firstName","show-message":!_vm.isUsePrimaryGuestAsLocalForm,"rules":[
      { type: 'string', message: 'Please type your first name in A-Z, 0-9', trigger: [ 'change', 'blur' ], pattern: /^[\w\d\s_\-]+$/ig },
      { required: true, message: 'First name is required', trigger: [ 'change', 'blur' ] }
    ]}},[_c('el-input',{attrs:{"placeholder":"E.g: Alice","name":"roomLeadGuestFirstName","autocomplete":"given-name","disabled":_vm.isUsePrimaryGuestAsLocalForm},on:{"blur":_vm.handleBlur},model:{value:(_vm.localForm.firstName),callback:function ($$v) {_vm.$set(_vm.localForm, "firstName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"localForm.firstName"}}),_c('p',{staticClass:"form-help-text"},[_vm._v("Please tell us who is going to stay.")])],1),_c('el-form-item',{staticClass:"guest-from--guest-last-name",attrs:{"label":"Guest family name","prop":"lastName","show-message":!_vm.isUsePrimaryGuestAsLocalForm,"rules":[
      { type: 'string', message: 'Please type your last name in A-Z, 0-9', trigger: [ 'change', 'blur' ], pattern: /^[\w\d\s_\-]+$/ig },
      { required: true, message: 'Last name is required', trigger: [ 'change', 'blur' ] }
    ]}},[_c('el-input',{attrs:{"placeholder":"E.g: Wong","autocomplete":"family-name","name":"roomLeadGuestLastName","disabled":_vm.isUsePrimaryGuestAsLocalForm},on:{"blur":_vm.handleBlur},model:{value:(_vm.localForm.lastName),callback:function ($$v) {_vm.$set(_vm.localForm, "lastName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"localForm.lastName"}})],1),_c('el-form-item',{staticClass:"guest-from--nationality",attrs:{"label":"Nationality","prop":"nationality","rules":[
      { required: true, message: 'Nationality is required', trigger: [ 'change', 'blur' ] }
    ]}},[_c('el-select',{attrs:{"filterable":"","autocomplete":"no","placeholder":"Nationality","name":"roomLeadNationality"},model:{value:(_vm.localForm.nationality),callback:function ($$v) {_vm.$set(_vm.localForm, "nationality", $$v)},expression:"localForm.nationality"}},_vm._l((_vm.countries),function(it){return _c('el-option',{key:it.a3,attrs:{"value":it.a2,"label":it.lb}})}),1)],1),_c('el-form-item',{staticClass:"guest-from--remarks",attrs:{"label":"Remarks (optional)","prop":"remarks","rules":[
      { type: 'string', max: 250, message: 'Your room remarks is more than 250 characters, please shorten it.', trigger: [ 'change', 'blur' ]}
    ]}},[_c('el-input',{attrs:{"type":"textarea","placeholder":"E.g: non-smoking room (Limit 250 characters)","autosize":{ minRows: 2, maxRows: 5}},on:{"blur":_vm.handleBlur},model:{value:(_vm.localForm.remarks),callback:function ($$v) {_vm.$set(_vm.localForm, "remarks", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"localForm.remarks"}}),_c('p',{staticClass:"form-help-text"},[_vm._v("Special/accessibility requests are not guaranteed and may incur additional charges.")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }