<template>
  <div>
    <transition name="view-fade-slow" mode="out-in">
      <component v-if="order.status" v-bind:is="statusComponent" :order="order" :payment="payment"></component>
    </transition>
    <!-- <div>{{ statusComponent }} {{ booking.status }}</div> -->
  </div>
</template>

<script>
/**
 *
 * "email.booking.status.tagline.pending": "<h3>Thanks for your booking.</h3><p><strong>Your booking is being processed.</strong><br><strong>You will notifed about booking status through email.</strong></p>",
  "email.booking.status.tagline.not-started": "<h3>Thanks for your booking.</h3><p><strong>Your booking is still pending.</strong><br><strong>You will notifed about booking status through email.</strong></p>",
  "email.booking.status.tagline.confirmed": "<h3>Your booking has been confirmed.</h3><p><strong>We have confirmed your hotel reservation with the property.</strong></p>","email.booking.status.tagline.failed": "<h3>Sorry, we are unable to process your booking.</h3><p><strong>Please try again.</strong></p><p><strong >For more information please contact customer support.</strong></p>",
  "email.booking.status.tagline.payment-failed": "<h3>Sorry, we unable to process your payment information.</h3><p><strong>Please contact customer support to proceed with the booking.</strong></p>",
  "email.booking.status.tagline.cancelled": "<h3>Your booking is now cancelled.</h3><p><strong>Your card refund is currently being processed.</strong></p>",
 */
import TransactionStatusConfirmed from './TransactionStatusConfirmed'
import TransactionStatusPending from './TransactionStatusPending'
import TransactionStatusNotStarted from './TransactionStatusNotStarted'
import TransactionStatusBookingFailure from './TransactionStatusBookingFailure'
import TransactionStatusPaymentFailure from './TransactionStatusPaymentFailure'
import TransactionStatusCancelled from './TransactionStatusCancelled'
import TransactionStatusCancelling from './TransactionStatusCancelling'

export default {
  name: 'OrderMessage',
  components: {
    [TransactionStatusConfirmed.name]: TransactionStatusConfirmed,
    [TransactionStatusPending.name]: TransactionStatusPending,
    [TransactionStatusNotStarted.name]: TransactionStatusNotStarted,
    [TransactionStatusBookingFailure.name]: TransactionStatusBookingFailure,
    [TransactionStatusPaymentFailure.name]: TransactionStatusPaymentFailure,
    [TransactionStatusCancelled.name]: TransactionStatusCancelled,
    [TransactionStatusCancelling.name]: TransactionStatusCancelling
  },
  props: {
    order: Object,
    payment: Object
  },
  computed: {
    statusComponent () {
      switch (this.order.status && this.order.status.code) {
        case 'ord-cf':
          return 'TransactionStatusConfirmed'
        case 'ord-ip':
          return 'TransactionStatusPending'
        case 'ord-af':
          return 'TransactionStatusBookingFailure'
        case 'ord-cx':
          return 'TransactionStatusCancelled'
        case 'ord-cxip':
          return 'TransactionStatusCancelling'
        /**
         * Stripe charge status
         * succeeded, pending, or failed.
         */
        case 'ord-pf':
          return 'TransactionStatusPaymentFailure'
        default:
          // bkg-ns
          return 'TransactionStatusNotStarted'
      }
    }
  }
}
</script>

<style>

</style>
