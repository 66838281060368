<template>
  <div>
    <i class="is-size-2 el-icon-error has-text-danger" />
    <div class="gap"></div>
    <h3 class="title is-4 has-text-weight-semibold has-text-danger">
      Sorry, we unable to process your payment information.
    </h3>
    <p class="subtitle is-6">
      &OpenCurlyDoubleQuote;{{ payment.failureMessage }}&CloseCurlyDoubleQuote;
      <br>
      Please try again or contact customer support to proceed with the booking.
    </p>
  </div>
</template>

<script>
export default {
  name: 'TransactionStatusPaymentFailure',
  props: {
    order: Object,
    payment: Object
  }
}
</script>

<style>

</style>
